body {
  background-color: #121212; /* Dark background */
}

.title {
  user-select: none;
  color: #fff; /* White text for visibility */
  font-family: monospace;
  font-weight: bold;
  text-align: center;
  margin: 42px auto 0;
}

.range-slider {
  background-color: #2c2f33; /* Darker shade for the slider background */
  width: 960px;
  margin: 24px auto 0;
}

.range-slider.margin-lg {
  margin: 42px auto 0;
}

/* Adjustments for specific sliders to fit the dark theme */
#range-slider-yellow,
#range-slider-ab,
#range-slider-gradient {
  background-color: #333; /* Dark background for sliders */
}

#range-slider-yellow .range-slider__range,
#range-slider-yellow .range-slider__thumb,
#range-slider-ab .range-slider__range,
#range-slider-ab .range-slider__thumb,
#range-slider-gradient .range-slider__range,
#range-slider-gradient .range-slider__thumb {
  /* Adjust colors for visibility on dark theme */
  background: #df2c2c; /* Example: Change to a color that's visible on dark backgrounds */
}

#range-slider-ab .range-slider__thumb[data-lower],
#range-slider-ab .range-slider__thumb[data-upper] {
  /* Ensure SVG icons within thumbs are visible against the dark theme */
  background-color: #ff4141; /* Adjusted for visibility */
}

@keyframes rotate-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-anti-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

/* Gradient Range Slider */
#range-slider-gradient {
  height: 24px;
  border-radius: 12px;
  background: #353644;
}

#range-slider-gradient .range-slider__thumb {
  border: 6px solid #fff;
  box-sizing: border-box;
}

#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #c2c2c2;
}

#range-slider-gradient .range-slider__thumb[data-upper] {
  background: #ee2c2c;
}

#range-slider-gradient .range-slider__range {
  background: linear-gradient(to right, #0073e6, #ee2c2c);
  background-size: 200% 100%;
  background-position: 50% 0;
}

#range-slider-gradient .range-slider__range[data-active],
#range-slider-gradient
  .range-slider__thumb[data-active]
  ~ .range-slider__range {
  animation: move-bg 0.75s infinite linear;
}

@keyframes move-bg {
  0% {
    background-position: 50% 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 50% 0;
  }

  75% {
    background-position: 0% 0;
  }

  100% {
    background-position: 50% 0;
  }
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

/* Vertical Range Slider */
.vertical-slider[data-vertical] {
  height: 180px;
}
